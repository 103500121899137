<template>
  <div>
    <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
      <v-flex xs12 pt-4>
        <v-layout wrap justify-center>
          <v-snackbar v-model="showSnackBar" color="black">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#42275a"
            spinner="spinner" />
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs11 lg8 pt-8 text-left>
            <span style="font-size: 25px;font-weight: bolder;">CANCELLED APPLICATIONS </span>
          </v-flex>
          <!-- <v-flex xl3 lg2 md3 pt-6 pr-4 sm3 xs12 align-self-center>
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" max-width="290">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable dense outlined readonly v-model="fromDate" v-bind="attrs" background-color="white"
                    v-on="on" @click:clear="from = null" style="color: black">
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        From Date</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="fromDate" color="#13736f" @change="menu1 = false"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xl3 lg2 md3 pt-6 pr-1 sm3 xs12 align-self-center>
              <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" max-width="290" elevation="0">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field clearable label="To Date" readonly dense background-color="white" color="#13736f" outlined
                    v-model="toDate" v-bind="attrs" v-on="on" @click:clear="from = null">
                    <template v-slot:append>
                      <v-icon color="black">mdi-chevron-down</v-icon>
                    </template>
                    <template v-slot:label>
                      <span class="custom-label-text" style="color: black; font-size: 14px">
                        To Date</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="toDate" color="#13736f" @change="menu2 = false"></v-date-picker>
              </v-menu>
            </v-flex> -->

        </v-layout>
      </v-flex>
      <v-flex xs12 pt-4 v-for="(item, i) in list" :key="i">
        <v-layout wrap>
          <v-flex xs12>
            <v-card>
              <v-layout wrap justify-center>
                <v-flex xs12 lg3 pt-3 pb-3 pl-3 v-if="item.status === 'Rejected'">

                  <div style="position: relative;">
                    <v-img height="100%" contain src="../../assets/Images/film.jpg"></v-img>
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                      <div
                        style="position: absolute; top: 10px; left: 10px; padding: 5px; background-color: red; color: white; font-weight: bold; border-radius: 5px;">
                        Rejected</div>
                    </div>
                  </div>

                </v-flex>

                <v-flex xs12 lg3 pt-3 pb-3 pl-3 v-else>
                  <v-layout wrap>
                    <v-flex xs12 v-if="item.paymentInitiation === 'YES'">

                      <div style="position: relative;">
                        <v-img height="100%" contain src="../../assets/Images/film.jpg"></v-img>
                        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                          <div
                            style="position: absolute; top: 10px; left: 10px; padding: 5px; background-color: #228b22 ; color: white; font-weight: bold; border-radius: 5px;">
                            Approved</div>
                        </div>
                      </div>


                    </v-flex>
                    <v-flex xs12 v-else>
                      <div style="position: relative;">
                        <v-img height="100%" contain src="../../assets/Images/film.jpg"></v-img>
                        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;">
                          <div
                            style="position: absolute; top: 10px; left: 10px; padding: 5px; background-color: #00bfff ; color: white; font-weight: bold; border-radius: 5px;">
                            Submitted</div>
                        </div>
                      </div>


                    </v-flex>
                  </v-layout>





                </v-flex>
                <v-flex xs12 lg9 pt-3>
                  <v-layout wrap justify-center fill-height>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex pl-4 xs12 lg3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Number

                          </span>
                          <br />
                          <span>{{ item.applicationNo }}</span>
                        </v-flex>
                        <v-flex pl-4 xs12 lg3 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Name

                          </span>
                          <br />
                          <span>{{ item.applicantName }}</span>
                        </v-flex>
                        <v-flex xs12 lg3 pl-4 pl-lg-0 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Disrict Name

                          </span>
                          <br />
                          <span>{{ item.applicantDistrict }}</span>
                        </v-flex>
                        <v-flex xs12 lg3 pl-4 pl-lg-0 text-left>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Mobile Number

                          </span>
                          <br />
                          <span>{{ item.applicantMobileNo }}</span>
                        </v-flex>
                        <v-flex xs12 lg3 text-left pl-4 pt-7 pt-xs-4>
                          <span class="subheading" style="font-size: 16px;">
                            Applicant Email

                          </span>
                          <br />
                          <span>{{ item.applicantEmail }}</span>
                        </v-flex>
                        <v-flex xs12 lg3 text-left pl-4 pt-4 v-if="item.status == 'Rejected'">
                          <span class="subheading" style="font-size: 16px;color: red;">
                            Reject Reason

                          </span>
                          <br />
                          <span style="font-size: 16px;color: red;">{{ item.rejectReasondfo }}</span>
                        </v-flex>
                        <!-- <v-flex xs12 lg3 text-left pl-4 pt-6 v-if="item.paymentInitiation === 'YES'">
                            <v-btn color="#2e8b57" @click="openDialog(item._id)">
        <span class="subheading" style="font-size: 16px;color: white;">
          Upload Agreement
        </span>
       
      </v-btn>
       <br />
         &nbsp; {{ item.userAgreement }}
        <v-icon color="green" v-if="item.userAgreement">
  mdi-check
        </v-icon>
                          </v-flex>
                          <v-flex xs12 lg3 text-left  pt-6 v-if="item.paymentInitiation === 'YES' && item.paymentStatus === 'Pending'">
                            <v-btn color="#2e8b57"  @click="feePayment(item._id)">
        <span class="subheading" style="font-size: 16px;color: white;">
         Fee Payment
        </span>
      </v-btn>
                            
                          </v-flex>
                          <v-flex v-if="item.paymentStatus === 'Success'" xs12 lg3 text-left pt-6>
                            <span style="color:#228b22;font-weight:bold">Payment Done</span>
                          
                          </v-flex> -->

                      </v-layout>
                    </v-flex>
                    <v-flex xs12 align-self-center>
                      <v-layout wrap justify-start>
                        <v-flex xs12 lg2 pb-4 text-center>
                          <v-btn @click="$router.push('/CancelledApplicationView?id=' + item._id)" plain
                            class="text-capitalize" color="blue">
                            <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                            <span>VIEW DETAILS</span>
                          </v-btn>
                        </v-flex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <!-- <v-flex xs12 lg2 pb-4 text-center>
                          <v-btn @click="navigateToCancelledApplicationView(item._id)" plain class="text-capitalize"
                            color="blue">
                            <v-icon color="blue">mdi-eye</v-icon>&nbsp;
                            <span>VIEW DETAILS</span>
                          </v-btn>
                        </v-flex>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->

                        <v-flex xs12 lg2 pb-4 text-center v-if="item.status === 'Cancelled'">
                          <v-btn block class="text-capitalize bgcustom" @click="getCancellation(item._id)">
                            <v-icon color="white">mdi-download</v-icon>
                            <span style="color:white;font-size: small;">CANCELLATION ORDER</span>
                          </v-btn>
                        </v-flex>
                        <!-- <v-flex xs12 lg2 pb-4 text-center v-if="item.status !== 'Rejected'">
                            <v-btn plain class="text-capitalize" @click="setCurIdToDelete(item._id), (deletedialog = true)"
                              color="red">
                              <v-icon color="red">mdi-cancel</v-icon>&nbsp;
                              <span>CANCEL</span>
                            </v-btn>
                          </v-flex>
                          <v-flex xs12 lg2 pb-4 text-center >
                            <v-btn height="45px"  class="text-capitalize" @click="getApplication(item._id)"
                              color="blue">
                              <v-icon color="white">mdi-download</v-icon>
                              <span style="color:white;font-size: small;">DOWNLOAD<br /> APPLICATION</span>
                            </v-btn>
                          </v-flex>
                          <v-flex xs12 lg2 pb-4 text-center pl-4>
                            <v-btn height="45px"  class="text-capitalize" @click="getAgreement(item._id)"
                              color="blue">
                              <v-icon color="white">mdi-download</v-icon>
                              <span style="color:white;font-size: small;">DOWNLOAD<br /> AGREEMENT </span>
                            </v-btn>
                          </v-flex> -->

                        <!-- <v-flex xs12 lg2 pb-4 text-center v-if="item.paymentStatus === 'Success'">
                            <v-btn plain class="text-capitalize" @click="forwardToClerk(item._id)"
                              color="orange">
                              <v-icon color="orange">mdi-share</v-icon>&nbsp;
                              <span>FORWARD</span>
                            </v-btn>
                          </v-flex> -->
                        <!-- <v-flex xs3 pl-4 v-if="item.superAdminApprovalStatus === 'rejected' " >
  
  <span>Application Rejected by Admin</span>
  <v-icon color="red">mdi-close-circle-outline</v-icon>
  
  </v-flex> -->

                      </v-layout>
                    </v-flex>

                  </v-layout>

                </v-flex>



              </v-layout>
            </v-card>
          </v-flex>


        </v-layout>


      </v-flex>
      <v-dialog v-model="dialogVisible" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="mainfont">
              Upload Agreement

            </span>
          </v-card-title>
          <v-card-text>
            <v-card>

            </v-card>
            <v-file-input class="mainfont" v-model="file" label="Choose a file"></v-file-input>
          </v-card-text>
          <v-card-actions>

            <v-btn class="mainfont" @click="uploadAgreement(currentItemId)" color="primary">Yes</v-btn>
            <v-btn @click="closeDialog" color="error">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog :retain-focus="true" persistent v-model="deletedialog" max-width="600px">
        <v-card>
          <v-card-title class="mainfont" style="color: black; font-size: 18px; font-weight: lighter">Are you sure you want
            to Cancel?</v-card-title>
          <v-card-actions class="d-flex justify-center text-center">
            <v-spacer></v-spacer>
            <v-btn color="black" text @click="deletedialog = false" class="mainfont">Cancel</v-btn>
            <v-btn color="red" class="mainfont" text @click="deleteItem()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout wrap>
      <v-flex text-center xs12 v-if="list.length < 1">
        <span>No Applications !</span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 v-if="list.length > 0">
        <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
          v-model="currentPage" color="#283e51"></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
// import CryptoJS from "crypto-js";

export default {


  data() {
    return {
      itemArray2: ["Vegeterian", "Non-vegetarian"],
      // cdate: moment().format("YYYY-MM-DD"),
      curIdToDelete: null,
      name: null,
      editdialog: false,
      barlist: [],
      edit: [],
      userProfileTabs: null,
      subtab: null,
      appLoading: false,
      page: 1,
      keyArray: [],
      dialogVisible: false,
      file: null,
      currentItemId: null,
      pages: 0,
      dialog1: false,
      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      list: [],
      flag: false,
      flag2: false,
      auctionbased: [],
      currentpage: 1,
      menu1: false,
      cdate: null,
      menu2: false,
      menu3: false,
      menu4: false,
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: null,
      fromDate2: null,
      toDate2: null,
      msg: null,
      currentPage: 1,
      showSnackBar: false,
      info: [],
      dialog2: false,
      viewdialog: false,
    };
  },

  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    fromDate() {
      this.getList();
    },
    toDate() {
      this.getList();
    },
  },
  computed: {
    guestPhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formattedDate() {
      const date = new Date(this.edit.selectedDate);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },


  methods: {
  //   navigateToCancelledApplicationView(id) {
  //   const encryptedId = CryptoJS.AES.encrypt(id, 'encryptionSecret').toString();
  //   this.$router.push({
  //     path: '/CancelledApplicationView',
  //     query: {
  //       id: encryptedId
  //     }
  //   });
  // },
    getCancellation(itemId) {
      this.selectedItem = itemId;
      this.getCancellation1(this.selectedItem);
    },
    getCancellation1() {
      const token = localStorage.getItem("token");
      const downloadUrl = "/cancelby/applicant/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: this.selectedItem,
        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);

          // Open the URL in a new tab
          const newTab = window.open();
          newTab.document.write('<iframe width="100%" height="100%" src="' + url + '" type="application/pdf"></iframe>');
          newTab.document.close();
        })
        .catch((error) => {
          console.error("Error downloading acknowledgement:", error);
        });
    },
    openDialog(itemId) {
      this.dialogVisible = true;
      this.currentItemId = itemId;
    },
    closeDialog() {
      this.dialogVisible = false;
      this.currentItemId = null;
    },
    uploadAgreement(itemId) {
      const formData = new FormData();
      formData.append('userAgreement', this.file);
      formData.append('appid', itemId);

      axios.post(`/user/agreement`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': localStorage.getItem('token'),
        },
      })
        .then(response => {
          console.log(response.data);
          this.closeDialog();
          location.reload();
        })
        .catch(error => {
          console.error(error);
        });
    },

    setCurIdToDelete(id) {
      this.curIdToDelete = id;
    },

    setCurIdToDelete2(id) {
      this.curIdToDelete2 = id;
    },

    getAgreement(appid) {
      const token = localStorage.getItem("token");
      const downloadUrl = "/agreement/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: appid,

        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "agreement.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },

    getApplication(appid) {
      const token = localStorage.getItem("token");
      const downloadUrl = "/application/pdf";
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          appid: appid,

        },
        responseType: "blob",
      };

      axios
        .get(downloadUrl, config)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "application.pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error downloading invoice:", error);
        });
    },

    deleteItem() {
      const idToDelete = this.curIdToDelete;

      if (!idToDelete) {
        return;
      }

      axios({
        url: "/cancel/application",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: idToDelete,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    forwardToClerk(feeid) {
      axios({
        url: "/check/forward",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: feeid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    feePayment(feeid) {
      axios({
        url: "/fees/submission",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: feeid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.deletedialog = false;
            window.location.reload();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(item) {
      var dt = new Date(item);
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/my/cancelled/applications",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          status: "yes",
          categoryId: this.categoryId,
          page: this.currentPage,
          fromDate: this.fromDate,
          toDate: this.toDate,
          limit: 10,
        },
      })
        .then((response) => {

          this.list = response.data.data.map((item) => {
            const date = new Date(item.selectedDate);
            item.selectedDate = date.toLocaleDateString();
            item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
            return item;
          });
          this.totalData = response.data.totalLength;
          this.selectedDatesArray = this.list.map((item) => item.selectedDate);
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

  },
};
</script>
    
<style>
.subheading {
  font-family: mainfont;
  color: #42275a;
  text-transform: none;
  font-size: 17px;
  font-weight: bold;

}

.heading {
  font-family: mainfont;
  color: rgb(229, 111, 8);
  text-transform: none;
  font-size: 25px;

}
</style>